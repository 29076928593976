import React from 'react';

import ContactForm from '@/components/organisms/ContactForm';
import FlashAdvantageSection from '@/components/organisms/FlashAdvantageSection';
import FlashBenefitsSection from '@/components/organisms/FlashBenefitsSection';
import MerchantDescriptionSection from '@/components/organisms/MerchantDescriptionSection';
import Footer from '@/components/organisms/Footer';
import MerchantHeroSection from '@/components/organisms/MerchantHeroSection';
import KycSection from '@/components/organisms/KycSection';
import EventSchedule from '@/components/organisms/EventSchedule';

function MerchantHome() {
  return (
    <>
      <MerchantHeroSection />
      <MerchantDescriptionSection />
      <FlashAdvantageSection />
      <FlashBenefitsSection />
      <KycSection />
      <EventSchedule />
      <ContactForm />
      <Footer renderBusinessLinks />
    </>
  );
}

export default MerchantHome;
