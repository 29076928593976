import React, { useState } from 'react';
import * as DOMPurify from 'dompurify';

import CollapseButton from '@/components/atoms/Button/CollapseButton';
import Footer from '@/components/organisms/Footer';
import ReturnButton from '@/components/atoms/Button/ReturnButton';
import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';
import {
  Container,
  ContentWrapper,
} from '@/components/molecules/Article/styles';

import { TextContentWrapper } from './styles';
import MerchantHeader from '@/components/organisms/MerchantHeader';

function TermsAndConditionsBusiness() {
  const [openedTopics, setOpenedTopics] = useState([]);

  const isTopicOpen = (title) => !!openedTopics.find((el) => el === title);

  const toggleTopic = (title) => {
    if (isTopicOpen(title)) {
      setOpenedTopics((state) => state.filter((el) => el !== title));
    } else {
      setOpenedTopics((state) => [...state, title]);
    }
  };

  return (
    <>
      <MerchantHeader />
      <Container>
        <ContentWrapper>
          <ReturnButton />
          <Text size="6xl">{i18n.t('termsAndConditionsBusiness.title')}</Text>
          <div>
            {i18n
              .t('termsAndConditionsBusiness.content', { returnObjects: true })
              .map((el, i) => {
                const topicIndex = i + 1;
                const formattedIndex =
                  topicIndex >= 10 ? topicIndex : `0${topicIndex}`;
                return (
                  <TextContentWrapper
                    key={el.title}
                    isOpen={isTopicOpen(el.title)}
                  >
                    <CollapseButton
                      isOpen={isTopicOpen(el.title)}
                      onClick={() => toggleTopic(el.title)}
                    />
                    <Text
                      size="3xl"
                      variant="secondary"
                      onClick={() => toggleTopic(el.title)}
                      style={{ cursor: 'pointer' }}
                    >
                      {`${formattedIndex}. ${el.title}`}
                    </Text>
                    <div>
                      {el?.renderAsHtml ? (
                        <Text
                          variant="secondary"
                          size="md"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(el?.content),
                          }}
                        />
                      ) : (
                        <Text variant="secondary" size="md">
                          {el.content}
                        </Text>
                      )}
                    </div>
                  </TextContentWrapper>
                );
              })}
          </div>
        </ContentWrapper>
      </Container>

      <Footer renderBusinessLinks />
    </>
  );
}

export default TermsAndConditionsBusiness;
