import styled, { css } from 'styled-components';

const spacing = {
  sm: 1,
  md: 2,
  lg: 3,
  xl: 5,
  xxl: 7,
  '2xl': 10,
  '3xl': 15,
};

export const SizedBox = styled.div`
  ${({ height = 0, width = 0 }) => css`
    height: ${spacing[height]}rem;
    width: ${spacing[width]}rem;
  `};
`;
