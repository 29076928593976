import React from 'react';
import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { Pagination, Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import FlashBenefitCard from '@/components/molecules/FlashBenefitCard';
import i18n from '@/translate/i18n';
import sectionImage from '@/assets/flash-benefits.png';
import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { Text } from '@/components/atoms/Typography/styles';

import { ContentWrapper, SwiperWrapper, TextWrapper } from './styles';

import 'swiper/css';
import 'swiper/css/pagination';

const SWIPER_BREAKPOINTS = {
  0: {
    slidesPerView: 0.8,
  },

  375: {
    slidesPerView: 1,
  },

  550: {
    slidesPerView: 1.5,
  },

  700: {
    slidesPerView: 2,
  },

  800: {
    slidesPerView: 2.5,
  },

  1024: {
    slidesPerView: 3,
  },
};

const data = i18n.t('flashBenefitsSection.benefits', { returnObjects: true });

function FlashBenefitsSection() {
  return (
    <DefaultContainer bgColor="dark">
      <ContentWrapper>
        <TextWrapper>
          <Text size="5xl" color="light">
            {i18n.t('flashBenefitsSection.title')}
          </Text>
          <Text size="md" color="light" variant="secondary">
            {i18n.t('flashBenefitsSection.description')}
          </Text>
        </TextWrapper>

        <img src={sectionImage} alt="Wallet and Flash app with Vpag Card" />
      </ContentWrapper>
      <SwiperWrapper
        breakpoints={SWIPER_BREAKPOINTS}
        modules={[Pagination, Navigation]}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        navigation={{
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev',
        }}
      >
        {data.map(({ title, content }) => (
          <SwiperSlide key={title}>
            <FlashBenefitCard title={title} description={content} />
          </SwiperSlide>
        ))}
        <ArrowRight className="swiper-btn-next" />
        <ArrowLeft className="swiper-btn-prev" />
      </SwiperWrapper>
    </DefaultContainer>
  );
}

export default FlashBenefitsSection;
