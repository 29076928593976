import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@/components/atoms/Typography/styles';
import { Wrapper } from './styles';

function FlashBenefitCard({ title, description }) {
  return (
    <Wrapper>
      <Text size="lg" color="light">
        {title}
      </Text>
      <Text size="sm" color="light" variant="secondary">
        {description}
      </Text>
    </Wrapper>
  );
}

FlashBenefitCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default FlashBenefitCard;
