import React from 'react';
import * as DOMPurify from 'dompurify';

import Footer from '@/components/organisms/Footer';
import ReturnButton from '@/components/atoms/Button/ReturnButton';
import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';
import {
  Container,
  ContentWrapper,
} from '@/components/molecules/Article/styles';

import { LinkButton, LinksWrapper, TextWrapper } from './styles';
import MerchantHeader from '@/components/organisms/MerchantHeader';

const associatedLinks = [
  { label: 'Cyber Patrol', url: 'https://www.cyberpatrol.com' },
  { label: 'GamBlock', url: 'http://www.gamblock.com' },
  { label: 'NetNanny', url: 'https://www.netnanny.com' },
  { label: 'Contentwatch', url: 'https://www.contentwatch.com' },
  { label: 'Optenetpc', url: 'http://www.optenetpc.com' },
  {
    label: 'Crawler parental control',
    url: 'https://crawler-parental-control.updatestar.com/pt',
  },
];

function MinimumAge() {
  return (
    <>
      <MerchantHeader />
      <Container>
        <ContentWrapper>
          <ReturnButton />
          <div>
            {i18n.t('minimumAge.content', { returnObjects: true }).map((el) => (
              <div key={el.title}>
                <Text size="5xl">{el.title}</Text>
                {el.paragraphs.map((item) => (
                  <TextWrapper key={item}>
                    {item?.renderAsHtml ? (
                      <Text
                        variant="secondary"
                        size="md"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.paragraph),
                        }}
                      />
                    ) : (
                      <Text variant="secondary" size="md">
                        {item.paragraph}
                      </Text>
                    )}
                  </TextWrapper>
                ))}
              </div>
            ))}
          </div>
          <LinksWrapper align="center" justify="center">
            {associatedLinks.map(({ label, url }) => (
              <LinkButton href={url} target="_blank" key={label}>
                {label}
              </LinkButton>
            ))}
          </LinksWrapper>
        </ContentWrapper>
      </Container>
      <Footer />
    </>
  );
}

export default MinimumAge;
