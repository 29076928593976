import i18n from '@/translate/i18n';

import { ReactComponent as Star } from '@/assets/icons/star.svg';
import { ReactComponent as Export } from '@/assets/icons/export.svg';
import { ReactComponent as Gift } from '@/assets/icons/gift.svg';
import { ReactComponent as Bank } from '@/assets/icons/bank.svg';

export default [
  {
    title: i18n.t('benefitsSection.fidelity'),
    icon: Star,
  },
  {
    title: i18n.t('benefitsSection.transactions'),
    icon: Export,
  },
  {
    title: i18n.t('benefitsSection.promotions'),
    icon: Gift,
  },
  {
    title: i18n.t('benefitsSection.merchants'),
    icon: Bank,
  },
];
