import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeft } from 'phosphor-react';
import { useHistory } from 'react-router-dom';

import i18n from '@/translate/i18n';
import { Button } from './styles';

function ReturnButton({ onClick }) {
  const history = useHistory();
  return (
    <Button type="button" onClick={onClick ?? (() => history.goBack())}>
      <ArrowLeft size={24} />
      <span>{i18n.t('button.return')}</span>
    </Button>
  );
}

ReturnButton.propTypes = {
  onClick: PropTypes.func,
};

export default ReturnButton;
