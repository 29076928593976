import React from 'react';

import i18n from '@/translate/i18n';
import vpagFlashAppImage from '@/assets/vpag-flash-app.png';
import vpagWalletAppImage from '@/assets/vpag-wallet-app.png';
import { ContactButton } from '@/components/atoms/Button/ContactButton/styles';
import { HashLink, Text } from '@/components/atoms/Typography/styles';

import {
  Container,
  ContentWrapper,
  FlashLogo,
  HideInDesktop,
  Image,
  ItemWrapper,
  LargeImage,
  TextWrapper,
  WalletLogo,
} from './styles';

function MerchantDescriptionSection() {
  return (
    <Container>
      <ContentWrapper>
        <ItemWrapper>
          <Image src={vpagFlashAppImage} alt="Vpag Flash app" />
          <TextWrapper>
            <FlashLogo />
            <Text size="2xl">
              {i18n.t('businessDescriptionSection.flashTitle')}
            </Text>
            <Text>{i18n.t('businessDescriptionSection.flashDescription')}</Text>
            <HashLink to="#contact">
              <ContactButton>{i18n.t('heroMerchant.contactUs')}</ContactButton>
            </HashLink>
          </TextWrapper>
        </ItemWrapper>
        <ItemWrapper reverse>
          <LargeImage src={vpagWalletAppImage} alt="Vpag Wallet app" />
          <TextWrapper>
            <WalletLogo />
            <Text size="2xl">
              {i18n.t('businessDescriptionSection.walletTitle')}
            </Text>
            <Text>
              {i18n.t('businessDescriptionSection.walletDescription')}
            </Text>
            <HashLink to="#contact">
              <ContactButton>{i18n.t('heroMerchant.contactUs')}</ContactButton>
            </HashLink>
          </TextWrapper>
        </ItemWrapper>
        <HideInDesktop>
          <HashLink to="#contact">
            <ContactButton>{i18n.t('heroMerchant.contactUs')}</ContactButton>
          </HashLink>
        </HideInDesktop>
      </ContentWrapper>
    </Container>
  );
}

export default MerchantDescriptionSection;
