import styled from 'styled-components';

export const Container = styled.nav`
  align-items: center;
  display: flex;
  gap: 1rem;

  .button-container {
    display: flex;
    gap: 0.3rem;
  }

  .merchant-link {
    position: relative;
    margin-inline: 0.6rem;

    &::after {
      bottom: 0;
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
      content: '';
      left: 0;
      margin-bottom: -0.3rem;
      position: absolute;
      width: 100%;
    }
  }
`;
