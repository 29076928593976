import React, { useState } from 'react';
import * as DOMPurify from 'dompurify';

import CollapseButton from '@/components/atoms/Button/CollapseButton';
import Footer from '@/components/organisms/Footer';
import Header from '@/components/organisms/Header';
import ReturnButton from '@/components/atoms/Button/ReturnButton';
import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';
import {
  Container,
  ContentWrapper,
} from '@/components/molecules/Article/styles';

import { TextContentWrapper } from './styles';

function Faq() {
  const [openedTopics, setOpenedTopics] = useState([]);

  const isTopicOpen = (title) => !!openedTopics.find((el) => el === title);

  const toggleTopic = (title) => {
    if (isTopicOpen(title)) {
      setOpenedTopics((state) => state.filter((el) => el !== title));
    } else {
      setOpenedTopics((state) => [...state, title]);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <ContentWrapper>
          <ReturnButton />
          <Text size="6xl">{i18n.t('faq.title')}</Text>
          {i18n.t('faq.sections', { returnObjects: true }).map((el) => (
            <div key={el.title}>
              <Text size="lg">{el.title}</Text>
              {el.content.map(({ question, answer, renderAsHtml }) => (
                <TextContentWrapper
                  key={question}
                  isOpen={isTopicOpen(question)}
                >
                  <CollapseButton
                    isOpen={isTopicOpen(question)}
                    onClick={() => toggleTopic(question)}
                  />
                  <Text
                    size="xl"
                    variant="secondary"
                    onClick={() => toggleTopic(question)}
                    style={{ cursor: 'pointer' }}
                  >
                    {question}
                  </Text>
                  {isTopicOpen(question) && (
                    <div>
                      {renderAsHtml ? (
                        <Text
                          variant="secondary"
                          size="md"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(answer),
                          }}
                        />
                      ) : (
                        <Text variant="secondary" size="md">
                          {answer}
                        </Text>
                      )}
                    </div>
                  )}
                </TextContentWrapper>
              ))}
            </div>
          ))}
        </ContentWrapper>
      </Container>

      <Footer />
    </>
  );
}

export default Faq;
