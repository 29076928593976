import React from 'react';

import i18n from '@/translate/i18n';
import { ExternalLink, HashLink } from '@/components/atoms/Typography/styles';

import { Container } from './styles';

function MerchantNavLinks() {
  return (
    <Container>
      <ExternalLink href="https://eightroom.com" target="_blank" highlight>
        {i18n.t('merchantHeader.about')}
      </ExternalLink>
      <HashLink to="/business#integrationGuide">
        {i18n.t('merchantHeader.developerIntegrationGuide')}
      </HashLink>
      <HashLink to="/business#contact">
        {i18n.t('merchantHeader.contact')}
      </HashLink>
    </Container>
  );
}

export default MerchantNavLinks;
