import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

const LinkStyles = css`
  ${({ theme, variant = 'primary', highlight }) => css`
    border-bottom: ${highlight ? `1px solid ${theme.colors.primary}` : 'none'};
    color: ${theme.colors.light};
    font: ${theme.font.size.md} ${theme.font.family[variant]};
    text-decoration: none;
    transition: 0.3s color ease;

    &:hover {
      color: ${theme.colors.secondary};
    }
  `}
`;

export const NavLink = styled(Link)`
  ${LinkStyles}
`;

export const ExternalLink = styled.a`
  ${LinkStyles}
`;

export const HashLink = styled(NavHashLink)`
  ${LinkStyles}
`;

export const HeroTitle = styled.h1`
  ${({ theme, color = 'primary', size = '5xl' }) => css`
    color: ${theme.colors[color]};
    font: ${`${theme.font.size['4xl']} ${theme.font.family.primary}`};
    line-height: 94.3%;

    @media screen and (min-width: ${theme.breakpoints.md}) {
      font-size: ${theme.font.size[size]} !important;
    }
  `}
`;

export const Text = styled.p`
  ${({ bold, color, size = 'base', theme, variant }) => css`
    color: ${theme.colors[color ?? 'dark']};
    font: ${bold ?? 'normal'} ${theme.font.size[size]}
      ${theme.font.family[variant ?? 'primary']};

    strong {
      color: ${theme.colors.primary};
      font: ${bold ?? 'normal'} ${theme.font.size[size]}
        ${theme.font.family.primary};
    }

    a {
      color: ${theme.colors.link.primary};
    }
  `}
`;
