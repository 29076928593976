import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: red;
  height: 100px;
  padding: 5rem 0;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 550px;
  padding: 5rem 1rem;
  text-align: center;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-wrap: nowrap;
  }
`;
