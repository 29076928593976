import styled from 'styled-components';

export const Wrapper = styled.span`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.font.family.secondary};
  gap: 0.3rem;

  a {
    margin-top: 5px !important;
  }
`;
