import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { ReactComponent as MainLogo } from '@/assets/simple-logo.svg';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.dark};
  padding: 1rem 0;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-color: ${({ theme, bg }) => bg ?? theme.colors.bg.dark};
  }
`;

export const ContentWrapper = styled.header`
  align-items: center;
  align-self: start;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  max-width: 1070px;
  padding-left: 0.5rem;
  width: 80%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-self: center;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

export const Logo = styled(MainLogo)`
  cursor: pointer;
  width: 100px;
`;

export const NavWrapper = styled.div`
  display: none;
  margin-right: 3rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;
