import React from 'react';
import PropTypes from 'prop-types';

import { ExternalLink, Text } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';
import { ContactButton } from '@/components/atoms/Button/ContactButton/styles';
import { SizedBox } from '@/components/atoms/SizedBox/styles';
import { SIGN_UP_URL } from '@/utils/constants';

import {
  Check,
  Column,
  DescriptionImage,
  TextWrapper,
  TitleContainer,
} from './styles';

function GridSection({
  buttonTitle,
  data,
  image,
  imageMaxWidth,
  imagePosition,
  isInverted,
  mobileTitleDisposition,
  reduceImage,
  title,
}) {
  return (
    <Column>
      {isInverted && <SizedBox />}
      <TextWrapper>
        <TitleContainer mobileTitleDisposition={mobileTitleDisposition}>
          <Text size="4xl" color="dark">
            {i18n.t(title)}
          </Text>
        </TitleContainer>
        <DescriptionImage
          src={image}
          top={imagePosition?.top}
          left={imagePosition?.left}
          right={imagePosition?.right}
          reduce={reduceImage}
          imageMaxWidth={imageMaxWidth}
        />
        <ul>
          {data.map((item) => (
            <li key={item}>
              <Check />
              <Text>{i18n.t(item)}</Text>
            </li>
          ))}
        </ul>
        <ExternalLink href={SIGN_UP_URL}>
          <ContactButton large>
            <Text>{i18n.t(buttonTitle)}</Text>
          </ContactButton>
        </ExternalLink>
      </TextWrapper>
    </Column>
  );
}

GridSection.propTypes = {
  image: PropTypes.string.isRequired,
  imageMaxWidth: PropTypes.string.isRequired,
  mobileTitleDisposition: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  isInverted: PropTypes.bool,
  reduceImage: PropTypes.bool,
  imagePosition: PropTypes.shape({
    top: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
    right: PropTypes.string.isRequired,
  }).isRequired,
};

export default GridSection;
