import i18n from '@/translate/i18n';

import { ReactComponent as Bank } from '@/assets/icons/bank.svg';
import { ReactComponent as Network } from '@/assets/icons/network.svg';
import { ReactComponent as Money } from '@/assets/icons/money-arrows.svg';
import { ReactComponent as BrazilIcon } from '@/assets/icons/brazil-icon.svg';

export default [
  {
    title: i18n.t('flashAdvantageSection.advantageA'),
    icon: Bank,
  },
  {
    title: i18n.t('flashAdvantageSection.advantageB'),
    icon: Network,
  },
  {
    title: i18n.t('flashAdvantageSection.advantageC'),
    icon: Money,
  },
  {
    title: i18n.t('flashAdvantageSection.advantageD'),
    icon: BrazilIcon,
  },
];
