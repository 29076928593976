import React from 'react';

import Footer from '@/components/organisms/Footer';
import Header from '@/components/organisms/Header';
import SignUpForm from '@/components/organisms/SignUpForm';
import sectionImage from '@/assets/form-image.png';

import { Container, ContentWrapper, DesktopImage } from './styles';

function SignUp() {
  return (
    <>
      <Header />
      <Container>
        <ContentWrapper>
          <SignUpForm />
          <DesktopImage src={sectionImage} alt="Wallet app and Flash app" />
        </ContentWrapper>
      </Container>
      <Footer />
    </>
  );
}

export default SignUp;
