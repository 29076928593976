import styled, { css } from 'styled-components';
import { NavHashLink } from 'react-router-hash-link';

import { hexToRGBA } from '@/styles/colors';
import { ReactComponent as VpagLogo } from '@/assets/simple-logo-alternative.svg';

const LinkStyles = ({ theme }) => css`
  color: ${theme.colors.info.light};
  font-size: ${theme.font.size['2xl']};
  padding: 1rem 0;
  text-decoration: none;
  width: 95%;

  &:not(:last-child) {
    border-bottom: 1px solid ${hexToRGBA(theme.colors.primary, 0.5)};
  }
`;

export const MenuModalContainer = styled.div`
  ${({ theme, hideAnimation, padding = 0, showMenu }) => css`
    align-items: center;
    animation: ${showMenu
      ? ` goLeftAnimation  forwards`
      : ` goRightAnimation  forwards`};
    animation-duration: ${hideAnimation ? '0' : '0.5s'};
    background-color: ${theme.colors.bg.dark};
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 8vh;
    height: 100vh;
    justify-content: flex-start;
    left: 0;
    margin-left: 5%;
    padding-top: 3rem;
    position: absolute;
    top: 0;
    width: 95%;
    z-index: 99;

    @media screen and (min-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.light};
  position: absolute;
  right: 15px;
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  width: 100%;
`;

export const NavLink = styled(NavHashLink)`
  ${LinkStyles}
`;

export const ExternalNavLink = styled.a`
  ${LinkStyles}
`;

export const Logo = styled(VpagLogo)`
  height: 200px;
  margin-top: 5rem 0 2rem;
  width: 200px;
`;
