import styled, { css } from 'styled-components';

export const SimpleButton = styled.button`
  ${({ theme, color = 'primary', borderColor = 'primary' }) => css`
    background-color: ${color === 'transparent' ? color : theme.colors[color]};
    border: 1px solid ${theme.colors[borderColor]};
    border-radius: 0.2rem;
    padding: 0.45rem 1rem;
  `}
`;
