import styled from 'styled-components';

import {
  DefaultContainer,
  FlexDiv,
} from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.secondary};
  padding: 0.5rem 3rem 6rem;

  .logo {
    display: none;
  }

  .simple-logo {
    display: block;
    height: 8rem;
    width: 10rem;
  }

  .minimum-age {
    display: block;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 4.5rem 0 3rem;

    .minimum-age {
      bottom: 10%;
      left: 15%;
      position: absolute;
    }

    .logo {
      display: block;
      max-width: 250px;
    }

    .simple-logo {
      display: none;
    }
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 1070px;
  position: relative;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0;
  }
`;

export const Section = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.tertiary};
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  width: 100%;
`;

export const NavLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  ul {
    font-family: ${({ theme }) => theme.font.family.secondary};
  }

  li {
    list-style: none;
  }

  a {
    font-size: 1rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;

    li {
      list-style: unset;
      margin: 0.3rem 0 0.3rem 1rem;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    text-align: center;
  }
`;

export const StackContainer = styled(FlexDiv)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;
