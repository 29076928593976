import React from 'react';
import { SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { ArrowLeft, ArrowRight } from 'phosphor-react';

import BenefitCard from '@/components/molecules/BenefitCard';
import i18n from '@/translate/i18n';
import sectionImage from '@/assets/store-and-wallet.png';
import { Text } from '@/components/atoms/Typography/styles';

import { Container, Wrapper, Image, SwiperWrapper } from './styles';
import data from './data';

import 'swiper/css';
import 'swiper/css/pagination';

const SWIPER_BREAKPOINTS = {
  0: {
    slidesPerView: 1.25,
    spaceBetween: 10,
  },
  550: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  785: {
    slidesPerView: 3,
    spaceBetween: 30,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 40,
  },
};

function BenefitsSection() {
  return (
    <Container>
      <Wrapper>
        <Text size="4xl">{i18n.t('benefitsSection.title')}</Text>
        <Image src={sectionImage} />
      </Wrapper>
      <SwiperWrapper
        breakpoints={SWIPER_BREAKPOINTS}
        modules={[Pagination, Navigation]}
        pagination={{
          clickable: true,
        }}
        navigation={{
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev',
        }}
      >
        {data.map(({ title, icon }) => (
          <SwiperSlide>
            <BenefitCard key={title} title={title} icon={icon} />
          </SwiperSlide>
        ))}
        <ArrowRight className="swiper-btn-next" />
        <ArrowLeft className="swiper-btn-prev" />
      </SwiperWrapper>
    </Container>
  );
}

export default BenefitsSection;
