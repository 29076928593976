import React from 'react';
import { useLocation } from 'react-router-dom';

import { SimpleButton } from '@/components/atoms/Button/SimpleButton/styles';
import LanguageSelector from '@/components/molecules/LanguageSelector';
import {
  ExternalLink,
  NavLink,
  Text,
} from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';
import { SIGN_UP_URL, SIGN_IN_URL } from '@/utils/constants';

import { Container } from './styles';

function NavLinks() {
  const { pathname } = useLocation();
  const isHomePage = pathname === '/';

  return (
    <Container>
      <NavLink
        to="/business"
        size="lg"
        color="light"
        variant="secondary"
        className="merchant-link"
      >
        <Text size="sm" color="light">
          {i18n.t('header.merchants')}
        </Text>
      </NavLink>
      <div className="button-container">
        <ExternalLink href={SIGN_IN_URL}>
          <SimpleButton>
            <Text size="sm">{i18n.t('header.access')}</Text>
          </SimpleButton>
        </ExternalLink>
        <ExternalLink href={SIGN_UP_URL}>
          <SimpleButton color="transparent">
            <Text size="sm" color="light">
              {i18n.t('header.signup')}
            </Text>
          </SimpleButton>
        </ExternalLink>
      </div>
      {!isHomePage && <LanguageSelector />}
    </Container>
  );
}

export default NavLinks;
