import React from 'react';

import i18n from '@/translate/i18n';
import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { Text } from '@/components/atoms/Typography/styles';
import { ReactComponent as KycIcon } from '@/assets/icons/kyc-icon.svg';

import { ContentWrapper, Item } from './styles';

function KycSection() {
  return (
    <DefaultContainer bgColor="light">
      <ContentWrapper>
        <Text size="5xl">{i18n.t('kycSection.title')}</Text>
        <Item>
          <KycIcon />
          <Text size="lg">{i18n.t('kycSection.description')}</Text>
        </Item>
      </ContentWrapper>
    </DefaultContainer>
  );
}

export default KycSection;
