import React from 'react';

import descriptionA from '@/assets/description-1.png';
import GridSection from '@/components/molecules/GridSection';

import { Container, ContentWrapper, Logo, ColumnContainer } from './styles';

function DescriptionSection() {
  return (
    <Container>
      <ContentWrapper>
        <Logo />
        <ColumnContainer>
          <GridSection
            mobileTitleDisposition="100"
            imagePosition={{ top: '-10%', right: '0%', left: 'auto' }}
            imageMaxWidth="300px"
            image={descriptionA}
            title="descriptionSection.title"
            buttonTitle="button.iWantToBe"
            data={[
              'descriptionSection.advantageA',
              'descriptionSection.advantageB',
              'descriptionSection.advantageC',
            ]}
          />
        </ColumnContainer>
      </ContentWrapper>
    </Container>
  );
}

export default DescriptionSection;
