export default [
  {
    title: 'SBC Lisboa',
    schedule: '24 - 26 de setembro de 2024',
    location: 'Portugal',
    image: '/assets/events/sbc-lisboa.png',
    url: '/#contact',
  },
  {
    title: 'SBC Miami',
    schedule: '29 - 31 de outubro de 2024',
    location: 'Miami',
    image: '/assets/events/sbc-miami-1.png',
    url: '/#contact',
  },
  {
    title: 'SiGMA Malta',
    schedule: '11 - 14 de novembro de 2024',
    location: 'Malta',
    url: '/#contact',
    image: '/assets/events/sigma-malta.png',
  },
  {
    title: 'ICE Barcelona',
    schedule: '20 - 22 de janeiro de 2025',
    location: 'Barcelona',
    url: '/#contact',
    image: '/assets/events/ice-barcelona-2025.png',
  },
  {
    title: 'SBC Rio',
    schedule: '25 - 27 de fevereiro de 2025',
    location: 'Rio de Janeiro',
    url: '/#contact',
    image: '/assets/events/sbc-summit-rio.png',
  },
];
