import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'phosphor-react';

import { Button } from './styles';

const SIZE = 30;

function MerchantMenuBtn({ onClick }) {
  return (
    <Button type="button" onClick={onClick}>
      <List size={SIZE} />
    </Button>
  );
}

MerchantMenuBtn.propTypes = {
  onClick: PropTypes.func,
};

export default MerchantMenuBtn;
