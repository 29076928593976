import styled from 'styled-components';

import BgImage from '@/assets/bg-vpag-green.png';
import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background: ${({ theme }) => theme.colors.shadow} url(${BgImage}) no-repeat
    center;
  background-color: ${({ theme }) => theme.colors.primary};
  background-size: contain;
  padding: 4.5rem 2rem;
  width: 100%;

  & > p {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 4.5rem 0;
  }

  #hubspotForm {
    width: 90%;
    max-width: 1070px;
  }
`;
