import styled from 'styled-components';

export const Button = styled.div`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 1rem;
  top: 2.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
