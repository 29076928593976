import styled from 'styled-components';

export const Container = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.dark};
  display: flex;
  flex-direction: column;
  min-height: 750px;
  padding: 4rem 0;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: center;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 1070px;
`;

export const DesktopImage = styled.img`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    max-width: 550px;
    max-height: 500px;
    width: 100%;
  }
`;

export const MobileImage = styled.img`
  max-width: 550px;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
