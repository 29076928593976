import { useLocation } from 'react-router-dom';

const useChatVisibility = () => {
  const { pathname } = useLocation();

  const toggleVisibility = () => {
    const target = document.querySelector('#chat-widget-container');

    if (pathname === '/business') {
      target?.classList?.add('hide-chat');
    } else {
      target?.classList?.remove('hide-chat');
    }
  };

  return { toggleVisibility };
};

export default useChatVisibility;
