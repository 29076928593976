import React from 'react';

import { Container, ContentWrapper } from './styles';
import descriptionA from '@/assets/clubVPlus.png';
import { Text } from '@/components/atoms/Typography/styles';
import GridSection from '@/components/molecules/GridSection/index';
import i18n from '@/translate/i18n';

function Awards() {
  return (
    <Container>
      <ContentWrapper>
        <Text size="xl">{i18n.t('awardsSection.subtitle')}</Text>
        <GridSection
          mobileTitleDisposition="95"
          imagePosition={{ top: '-15%', left: 'auto', right: '0%' }}
          image={descriptionA}
          imageMaxWidth="500px"
          title="awardsSection.title"
          buttonTitle="awardsSection.howToButton"
          data={[
            'awardsSection.advantageA',
            'awardsSection.advantageB',
            'awardsSection.advantageC',
          ]}
        />
      </ContentWrapper>
    </Container>
  );
}

export default Awards;
