import styled, { css } from 'styled-components';

export const ContactButton = styled.button`
  ${({ theme, large, color = 'primary' }) => css`
    background-color: ${theme.colors[color]};
    border-radius: 8px;
    border: none;
    color: ${theme.colors.info[color === 'primary' ? 'dark' : 'light']};
    font: ${`${theme.font.size.md} ${theme.font.family.primary}`};
    height: 50px;
    width: ${large ? '300px' : '220px'};

    &:hover {
      filter: brightness(0.6);
    }
  `}
`;
