import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Slide, MenuNav } from 'hero-slider';

import Header from '@/components/organisms/Header';
import i18n from '@/translate/i18n';
import { ContactButton } from '@/components/atoms/Button/ContactButton/styles';
import { SizedBox } from '@/components/atoms/SizedBox/styles';
import {
  ExternalLink,
  HeroTitle,
  Text,
} from '@/components/atoms/Typography/styles';
import { SIGN_UP_URL } from '@/utils/constants';

import {
  AbsoluteWrapper,
  ContentWrapper,
  HeroSliderWrapper,
  TextPadding,
} from './style';
import { content, images } from './data';

const AUTOPLAY_DURATION = 7000;
const FIRST_SLIDE = 1;
const SLIDING_DURATION = 500;
const SLIDING_DELAY = 100;
const MOBILE_THRESHOLD = 9999999;

function HeroSection() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const currentBanner = content[currentSlide];

  return (
    <HeroSliderWrapper
      height="100vh"
      autoplay={{
        autoplayDuration: AUTOPLAY_DURATION,
      }}
      controller={{
        initialSlide: FIRST_SLIDE,
        slidingDuration: SLIDING_DURATION,
        slidingDelay: SLIDING_DELAY,
        onSliding: (nextSlide) => setCurrentSlide(nextSlide - 1),
      }}
      accessability={{
        shouldDisplayButtons: false,
      }}
      animations={{
        slidingAnimation: 'fade',
      }}
    >
      <AbsoluteWrapper>
        <Header bg="transparent" />
        <ContentWrapper>
          <HeroTitle color="light">{currentBanner.title}</HeroTitle>
          <TextPadding>
            <Text size="md" color="light" variant="secondary">
              <Trans
                i18n={i18n}
                i18nKey={currentBanner.content}
                components={[<strong />, <li />]}
              />
            </Text>
          </TextPadding>
          {currentBanner?.obs && <i>{currentBanner.obs}</i>}
          <SizedBox height="lg" />
          <ExternalLink href={SIGN_UP_URL}>
            <ContactButton>{currentBanner.button}</ContactButton>
          </ExternalLink>
        </ContentWrapper>
      </AbsoluteWrapper>
      {images.map((image) => (
        <Slide
          key={image}
          label="banner-1"
          background={{
            backgroundImageSrc: image,
            backgroundImageClassName: 'banner-bg',
          }}
        />
      ))}

      <MenuNav mobileThreshold={MOBILE_THRESHOLD} />
    </HeroSliderWrapper>
  );
}

export default HeroSection;
