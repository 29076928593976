import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const Container = styled.section`
  background: ${({ theme }) => theme.colors.bg.light};
  padding-bottom: 3rem;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 1070px;
  padding: 6rem 0;
  width: 100%;

  p {
    margin-bottom: 2rem;
    max-width: 550px;
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
  }
`;

export const Image = styled.img`
  max-width: 550px;
  padding: 1rem;
  width: 100%;
`;

export const SwiperWrapper = styled(Swiper)`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 1070px;
  padding-bottom: 50px;

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.colors.primary};
  }

  .slideNextClass {
    background-color: red;
    width: 50px;

    height: 50px;
  }

  .swiper-btn-prev,
  .swiper-btn-next {
    bottom: 5px;
    color: ${({ theme }) => theme.colors.link.primary};
    cursor: pointer;
    font-size: 25px;
    left: 20px;
    opacity: 1;
    position: absolute;
    z-index: 11;
  }

  .swiper-btn-next {
    left: auto;
    right: 20px;
  }

  .swiper-btn-prev.swiper-button-disabled,
  .swiper-btn-next.swiper-button-disabled {
    display: none;
  }
`;
