import React from 'react';

import i18n from '@/translate/i18n';
import { ContactButton } from '@/components/atoms/Button/ContactButton/styles';
import { ExternalLink, Text } from '@/components/atoms/Typography/styles';
import { SIGN_UP_URL } from '@/utils/constants';

import { Container } from './styles';

function SloganSection() {
  return (
    <Container>
      <Text color="light" size={i18n.t('sloganSection.titleSize')}>
        {i18n.t('sloganSection.title')}
      </Text>
      <ExternalLink href={SIGN_UP_URL}>
        <ContactButton type="button">
          {i18n.t('sloganSection.button')}
        </ContactButton>
      </ExternalLink>
    </Container>
  );
}

export default SloganSection;
