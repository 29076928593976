import React from 'react';

import i18n from '@/translate/i18n';
import { ExternalLink, Text } from '@/components/atoms/Typography/styles';

import { Container, Wrapper } from './styles';
import { ContactButton } from '@/components/atoms/Button/ContactButton/styles';

function GetInTouchSection() {
  return (
    <Container>
      <Wrapper>
        <Text size={i18n.t('getInTouch.titleSize')}>
          {i18n.t('getInTouch.title')}
        </Text>
        <ExternalLink href="mailto:help@vpag.com" target="_blank">
          <ContactButton color="dark">
            {i18n.t('getInTouch.buttonLabel')}
          </ContactButton>
        </ExternalLink>
      </Wrapper>
    </Container>
  );
}

export default GetInTouchSection;
