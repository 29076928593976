import styled, { css } from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { ReactComponent as MainLogo } from '@/assets/logo.svg';
import { ReactComponent as SecondaryLogo } from '@/assets/simple-logo-alternative.svg';

export const Container = styled(DefaultContainer)`
  ${({ theme, bg, marginTop }) => css`
    background-color: ${bg ?? theme.colors.bg.dark};
    display: flex !important;
    justify-content: center;
    padding: 1rem 0;
    position: relative;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.md}) {
      background-color: ${bg ?? theme.colors.bg.dark};
      margin-top: ${marginTop};
    }
  `}
`;

export const ContentWrapper = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 85%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-self: start;
    flex-direction: row;
    gap: 0;
    max-width: 1070px;
    padding-left: 0.5rem;
    width: 80%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-self: center;
  }
`;

export const LogoWrapper = styled.div`
  display: none;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: row;
  }
`;

export const MobileLogo = styled(SecondaryLogo)`
  cursor: pointer;
  height: 100px;
  width: 100px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const Logo = styled(MainLogo)`
  cursor: pointer;
  width: 300px;
`;

export const NavWrapper = styled.div`
  display: none;
  margin-right: 3rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;
