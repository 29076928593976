import { colors } from '../colors';

const dark = {
  name: 'dark',
  colors: {
    contrast: colors.yellowGreenDark[500],
    danger: colors.red,
    dark: colors.darkGray[900],
    divider: colors.gray[200],
    highlight: colors.yellowGreenDark[500],
    light: colors.white,
    pending: colors.blue[100],
    pix: colors.pix,
    primary: colors.yellowGreenLight[500],
    secondary: colors.yellowGreenDark[100],
    shadow: colors.black,
    success: colors.yellowGreenDark[500],
    lightGray: colors.lightGray,

    bg: {
      primary: colors.gray[50],
      secondary: colors.darkGray[900],
      tertiary: colors.darkGray[800],
      dark: colors.black,
      light: colors.white,
    },

    info: {
      light: colors.white,
      dark: colors.darkGray[900],
      disabled: colors.gray[500],
      strong: colors.gray[600],
    },

    input: {
      text: colors.gray[600],
      placeholder: colors.gray[400],
      lines: colors.gray[400],
    },

    link: {
      primary: colors.yellowGreenDark[500],
    },
  },

  font: {
    family: {
      primary: 'Arboria Medium, sans-serif',
      secondary: 'Arboria Book, sans-serif',
    },
    size: {
      xs: '0.75rem', // 12px
      sm: '0.875rem', // 14px
      base: '1rem', // 14px
      md: '1.125rem', // 18px
      lg: '1.25rem', // 20px
      xl: '1.5rem', // 24px
      '2xl': '1.86rem', // 30px
      '3xl': '2.25rem', // 36px
      '4xl': '3rem', // 48px
      '5xl': '3.75rem', // 60px
      '6xl': '4.5rem', // 72px
      '7xl': '6rem', // 96px

      xSmallCalc: 'calc(0.875em + 0.2vh)',
      smallCalc: 'calc(1em + 0.2vh)',
      mediumCalc: 'calc(1em + 0.5vh)',
    },
  },
  breakpoints: {
    xs: '375px',
    sm: '480px',
    md: '820px',
    lg: '1024px',
  },
};

export default dark;
