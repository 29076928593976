import styled from 'styled-components';

import { ReactComponent as GatewayLogo } from '@/assets/logo-wallet.svg';
import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  min-height: 55vh;
  overflow: hidden;
  padding: 4rem 0;
  background-color: ${({ theme }) => theme.colors.lightGray};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 5rem 0 10rem;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4vh;
  height: 100%;
  justify-content: center;
  max-width: 1070px;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: flex-start;
    padding: 0 2rem;
  }
`;

export const Logo = styled(GatewayLogo)``;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 12rem;
  }
`;
