import React from 'react';

import { HashLink } from 'react-router-hash-link';
import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';

import data from './data';

import { Container, ContentWrapper, Item, ItemsWrapper } from './styles';

function EventSchedule() {
  return (
    <Container id="events">
      <ContentWrapper>
        <Text size="4xl" weight="700" color="light">
          {i18n.t('eventSection.title')}
        </Text>
        <Text color="light">{i18n.t('eventSection.subtitle')}</Text>
        <ItemsWrapper>
          {data.map(({ title, schedule, location, image, isDisabled, url }) => (
            <Item key={title}>
              <img src={image} alt={title} />
              <Text size="lg" weight="500" color="light">
                {title}
              </Text>
              <Text weight="500" color="primary">
                {schedule}
              </Text>
              <Text color="light">{location}</Text>
              {!isDisabled && (
                <HashLink smooth to={`/business${url ?? ''}`}>
                  <button type="button">
                    {i18n.t('eventSection.requestAppointment')}
                  </button>
                </HashLink>
              )}
            </Item>
          ))}
        </ItemsWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default EventSchedule;
