import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 5rem 1rem;
  text-align: center;
`;

export const Item = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.bg.primary};
    display: flex;
    flex-direction: column;
    font-family: ${theme.font.family.secondary};
    gap: 1rem;
    grid-auto-rows: 100px;
    justify-content: space-around;
    max-width: 800px;
    padding: 2rem 0;
    text-align: center;

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      min-height: 150px;
    }

    @media screen and (min-width: ${theme.breakpoints.xs}) {
      flex-direction: row;
      height: 210px;
      text-align: left;

      p {
        align-self: center;
        max-width: 53%;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      padding: 1rem 2rem;
    }
  `}
`;
