import i18n from '@/translate/i18n';
import banner1 from '@/assets/banner-1.png';
import banner2 from '@/assets/banner-2.png';
import banner3 from '@/assets/banner-3.png';

export const images = [banner3, banner2, banner1];

export const content = [
  {
    title: i18n.t('hero.banner1.title'),
    content: i18n.t('hero.banner1.content'),
    button: i18n.t('hero.banner1.button'),
  },
  {
    title: i18n.t('hero.banner2.title'),
    content: i18n.t('hero.banner2.content'),
    button: i18n.t('hero.banner2.button'),
  },
  {
    title: i18n.t('hero.banner3.title'),
    content: i18n.t('hero.banner3.content'),
    button: i18n.t('hero.banner3.button'),
  },
];
