import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@/components/atoms/Typography/styles';
import { Wrapper } from './styles';

function BenefitCard({ title, icon: Icon }) {
  return (
    <Wrapper>
      <Icon />
      <Text size="lg"> {title}</Text>
    </Wrapper>
  );
}

BenefitCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
};

export default BenefitCard;
