import styled from 'styled-components';
import HeroSlider from 'hero-slider';

import CheckIcon from '@/assets/icons/check.svg';

export const HeroSliderWrapper = styled(HeroSlider)`
  .hero-slider-wrapper {
    min-height: 800px;
    position: relative;
  }

  .banner-bg {
    object-position: 72% 60%;

    &:after {
      background-image: linear-gradient(to top, transparent 0%, black 120%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .banner-bg:after {
      content: none;
    }
  }
`;

export const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  max-width: 1070px;
  width: 85%;

  h1 {
    font-size: ${({ theme }) => theme.font.size['5xl']};
  }

  i {
    font: ${({ theme }) =>
      ` ${theme.font.size.sm} ${theme.font.family.secondary}`};
  }
`;

export const TextPadding = styled.div`
  margin: 15vh 0 3vh;

  p {
    max-width: 95%;
  }

  li {
    padding: 0.3rem 0;
    list-style-image: url(${CheckIcon});
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 45%;
    margin: 5vh 0;
  }
`;

export const AbsoluteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5vh;
  height: 100vh;
  justify-content: ${({ justify = 'flex-start' }) => justify};
  min-height: 800px;
  position: absolute;
  width: 100%;
  z-index: 3;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 15vh;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 0 2rem;
  }
`;
