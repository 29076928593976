import React from 'react';
import TagManager from 'react-gtm-module';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '@/styles/global';
import ScrollToTop from '@/components/atoms/ScrollToTop';
import useChatVisibility from '@/hook/useChatVisibility';
import main from '@/styles/themes/main';
import { SIGN_UP_URL } from '@/utils/constants';

import {
  BusinessPrivacyPolicy,
  CookiePolicy,
  CustomerPrivacyPolicy,
  Faq,
  Home,
  MerchantHome,
  MinimumAge,
  ResponsibleGaming,
  TermsAndConditionsBusiness,
  TermsAndConditionsCustomer,
} from '@/components/pages';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};
TagManager.initialize(tagManagerArgs);

function App() {
  const { toggleVisibility } = useChatVisibility();

  toggleVisibility();

  return (
    <ThemeProvider theme={main}>
      <GlobalStyle />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/business" component={MerchantHome} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditionsCustomer}
        />
        <Route
          exact
          path="/business/terms-and-conditions"
          component={TermsAndConditionsBusiness}
        />
        <Route exact path="/privacy-policy" component={CustomerPrivacyPolicy} />
        <Route
          exact
          path="/business/privacy-policy"
          component={BusinessPrivacyPolicy}
        />
        <Route exact path="/cookie-policy" component={CookiePolicy} />
        <Route exact path="/responsible-gaming" component={ResponsibleGaming} />
        <Route exact path="/minimum-age" component={MinimumAge} />
        <Route exact path="/faq" component={Faq} />
        <Route
          exact
          path="/signup"
          component={() => {
            window.location.href = SIGN_UP_URL;
            return null;
          }}
        />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
