import styled from 'styled-components';

export const Container = styled.section`
  background: ${({ theme }) => theme.colors.primary};
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 1070px;
  padding: 6rem 0;
  width: 100%;

  p {
    margin-bottom: 2rem;
    max-width: 90%;
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 6rem 2rem;

    p {
      max-width: 55%;
      text-align: left;
    }
  }
`;
