import React from 'react';

import Article from '@/components/molecules/Article';
import Footer from '@/components/organisms/Footer';
import Header from '@/components/organisms/Header';
import ReturnButton from '@/components/atoms/Button/ReturnButton';
import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';
import {
  Container,
  ContentWrapper,
} from '@/components/molecules/Article/styles';

import { Table } from './styles';

function CustomerPrivacyPolicy() {
  const tableFields = i18n.t('customerPrivacyPolicy.table.fields', {
    returnObjects: true,
  });

  return (
    <>
      <Header />
      <Container>
        <ContentWrapper>
          <ReturnButton />
          <Text>{i18n.t('customerPrivacyPolicy.effectiveDate')}</Text>
          <Article
            textContent={i18n.t('customerPrivacyPolicy.content', {
              returnObjects: true,
            })}
          />
          <Text size="5xl">{i18n.t('customerPrivacyPolicy.table.title')}</Text>
          <Table>
            {tableFields.map(({ description, content }) => (
              <tr key={description}>
                <td>
                  <div>{description}</div>
                </td>
                <td>
                  <div>{content}</div>
                </td>
              </tr>
            ))}
          </Table>
        </ContentWrapper>
      </Container>
      <Footer />
    </>
  );
}

export default CustomerPrivacyPolicy;
