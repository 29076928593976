import styled from 'styled-components';

export const Button = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  right: 0;
  top: 1rem;
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
  transition: 0.3s transform ease;
`;
