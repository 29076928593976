import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import MenuButton from '@/components/atoms/Button/MenuButton';
import MobileModal from '@/components/organisms/MobileModal';
import NavLinks from '@/components/molecules/NavLinks';

import {
  Container,
  ContentWrapper,
  LogoWrapper,
  Logo,
  NavWrapper,
  MobileLogo,
} from './styles';

function Header({ bg }) {
  const history = useHistory();
  const { pathname } = history.location;
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showMenu]);

  return (
    <Container bg={bg} marginTop={pathname === '/' ? '2rem' : 0}>
      <ContentWrapper>
        <LogoWrapper>
          <Logo onClick={() => history.push('/')} />
        </LogoWrapper>

        <NavWrapper>
          <NavLinks />
        </NavWrapper>
        <MobileLogo onClick={() => history.push('/')} />
        <MenuButton
          onClick={() => setShowMenu((state) => !state)}
          showMenu={showMenu}
        />
        <MobileModal showMenu={showMenu} setShowMenu={setShowMenu} />
      </ContentWrapper>
    </Container>
  );
}

Header.propTypes = {
  bg: PropTypes.string,
};
export default Header;
