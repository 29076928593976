import styled, { css } from 'styled-components';

import { ReactComponent as Checkmark } from '@/assets/icons/simple-check.svg';

export const TextWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  text-align: center;

  button {
    display: none;
  }

  ul {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin: 1rem auto;
  }

  ul li {
    align-items: center;
    display: flex;
    gap: 1.5rem;
    justify-content: flex-start;
    max-width: 21.8rem;
    text-align: left;

    svg {
      flex-shrink: 0;
      width: 2rem;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    text-align: left;

    ul {
      gap: 1rem;
      margin: 2rem 0;
    }

    ul li {
      max-width: 23.75rem;
    }

    button {
      display: block;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0;
  }
`;

export const TitleContainer = styled.div`
  margin: auto;
  width: ${({ mobileTitleDisposition = 50 }) => mobileTitleDisposition}%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0;
    text-align: left;
    width: 70%;
  }
`;

export const DescriptionImage = styled.img`
  ${({
    imageMaxWidth = '600px',
    isOutContent,
    left,
    reduce,
    right = 'auto',
    theme,
    top,
  }) => css`
    display: ${isOutContent ? 'none' : 'block'};
    max-width: ${imageMaxWidth};
    padding: 0 1.3rem;
    position: relative;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.md}) {
      bottom: 0;
      display: block;
      height: auto;
      left: ${left};
      right: ${right};
      padding: 0;
      position: absolute;
      top: ${top};
      width: ${reduce ? '29rem' : '45rem'};
    }
  `}
`;

export const Column = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column-gap: 4rem;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Check = styled(Checkmark)``;
