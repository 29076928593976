import styled from 'styled-components';
import { hexToRGBA } from '@/styles/colors';

export const Container = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 0.2rem;
  position: relative;
  text-transform: uppercase;

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const DropdownMenu = styled.ul`
  background-color: ${({ theme }) => hexToRGBA(theme.colors.bg.secondary, 0.5)};
  bottom: -3.6rem;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.75);
  left: -1rem;
  min-width: 70px;
  position: absolute;
  z-index: 7;
`;

export const Option = styled.li`
  font-size: ${({ theme }) => theme.font.size.md};
  list-style: none;
  padding: 0.2rem 0.5rem;

  &:hover {
    background-color: ${({ theme }) =>
      hexToRGBA(theme.colors.bg.secondary, 0.8)};
  }
`;
