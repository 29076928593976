import React from 'react';
import PropTypes from 'prop-types';
import * as DOMPurify from 'dompurify';

import { Text } from '@/components/atoms/Typography/styles';
import { TextContentWrapper, TextWrapper } from './styles';

function Article({ textContent }) {
  if (!textContent.length) {
    return null;
  }

  return (
    <TextContentWrapper>
      {textContent?.map((el, i) => (
        <div key={el.title}>
          <Text size="5xl">{el?.title ?? ''}</Text>
          {el.paragraphs.map((item) => (
            <TextWrapper key={item?.paragraph}>
              {item?.renderAsHtml ? (
                <Text
                  variant="secondary"
                  size="md"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.paragraph),
                  }}
                />
              ) : (
                <Text variant="secondary" size="md">
                  {item?.paragraph ?? ''}
                </Text>
              )}
            </TextWrapper>
          ))}
        </div>
      ))}
    </TextContentWrapper>
  );
}

Article.propTypes = {
  textContent: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paragraphs: PropTypes.arrayOf(
        PropTypes.shape({
          paragraph: PropTypes.string.isRequired,
          renderAsHtml: PropTypes.bool,
        }),
      ),
    }),
  ),
};
export default Article;
