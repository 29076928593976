import styled from 'styled-components';

export const Table = styled.table`
  color: black;
  width: 100%;

  tr {
    border: 1px solid black;
  }

  td {
    border: 1px solid black;
    padding: 2rem 0;

    div {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0 1rem;
      width: 100%;
    }
  }

  td:first-child {
    max-width: 200px;
  }
`;
