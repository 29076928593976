import Flag from 'react-world-flags';
import React, { useRef, useState } from 'react';
import { Globe } from 'phosphor-react';

import useOnClickOutside from '@/components/hooks/useClickOutside';
import { Text } from '@/components/atoms/Typography/styles';
import { Container, DropdownMenu, Option } from './styles';

const I18N_STORAGE_KEY = 'i18nextLng';

const languages = [
  { label: 'EN', value: 'en', flag: 'us' },
  { label: 'PT', value: 'pt', flag: 'br' },
];

function LanguageSelector() {
  const [language] = useState(localStorage.getItem(I18N_STORAGE_KEY));
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, setShowMenu);

  const handleChange = (lang) => {
    localStorage.setItem(I18N_STORAGE_KEY, lang);
    window.location.reload();
  };

  return (
    <Container onClick={() => setShowMenu((state) => !state)} ref={ref}>
      <Text size="md" color="light">
        {language}
      </Text>
      <Globe size={24} />
      {showMenu && (
        <DropdownMenu>
          {languages.map(({ label, value, flag }) => (
            <Option key={value} onClick={() => handleChange(value)}>
              <Flag code={flag} width={20} /> {label}
            </Option>
          ))}
        </DropdownMenu>
      )}
    </Container>
  );
}

export default LanguageSelector;
