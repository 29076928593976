import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 410px;
  padding: 0 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 50%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin: 1rem 0;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Input = styled.input`
  border: none;
  border-radius: 5px;
  font: ${({ theme }) =>
    `${theme.font.size.md} ${theme.font.family.secondary}`};
  height: 40px;
  outline: none;
  padding-left: 0.5rem;
  width: 100%;
`;

export const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 5px;
  font: ${({ theme }) =>
    `${theme.font.size.base} ${theme.font.family.secondary}`};
  height: 35px;
  margin-top: 0.5rem;
  max-width: 160px;
  min-width: 138px;
  width: 40%;
`;
