import styled from 'styled-components';

export const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  height: 100vh;
  width: 100%;

  video {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 10vh;
  }
`;

export const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  max-width: 1070px;
  padding: 0 2rem 2rem;
  width: 100%;
`;

export const TextPadding = styled.div`
  padding: 0 20rem 0 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0;
  }
`;

export const AbsoluteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15vh;
  height: 100%;
  position: absolute;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 20vh;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 0 2rem;
  }
`;
