import styled from 'styled-components';

import BgImage from '@/assets/bg-vpag-dark.svg';
import BgImageMb from '@/assets/bg-vpag-dark-mb.png';

export const Container = styled.section`
  align-items: center;
  background: ${({ theme }) => theme.colors.shadow} url(${BgImageMb}) no-repeat
    bottom;
  background-size: contain;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  justify-content: center;
  padding: 6rem 0;
  text-align: center;
  width: 100%;

  p {
    font-size: 3rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background: ${({ theme }) => theme.colors.shadow} url(${BgImage}) no-repeat
      center;
  }
`;
