import React from 'react';

import heroVideo from '@/assets/hero-video.mp4';
import i18n from '@/translate/i18n';
import { ContactButton } from '@/components/atoms/Button/ContactButton/styles';
import {
  HashLink,
  HeroTitle,
  Text,
} from '@/components/atoms/Typography/styles';

import MerchantHeader from '../MerchantHeader';

import {
  AbsoluteWrapper,
  Container,
  ContentWrapper,
  TextPadding,
} from './style';

function MerchantHeroSection() {
  return (
    <Container>
      <video autoPlay loop muted playsInline id="bg-video">
        <source src={heroVideo} type="video/mp4" />
      </video>
      <AbsoluteWrapper>
        <MerchantHeader bg="transparent" />
        <ContentWrapper>
          <HeroTitle>{i18n.t('heroMerchant.title')}</HeroTitle>
          <TextPadding>
            <Text size="lg" color="light">
              {i18n.t('heroMerchant.content')}
            </Text>
          </TextPadding>
          <HashLink to="#contact">
            <ContactButton>{i18n.t('heroMerchant.contactUs')}</ContactButton>
          </HashLink>
        </ContentWrapper>
      </AbsoluteWrapper>
    </Container>
  );
}

export default MerchantHeroSection;
