import React, { useEffect } from 'react';
import { Text } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';
import { Container } from './styles';

const HubspotContactForm = () => {
  const lang = localStorage.getItem('i18nextLng');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: '20494613',
          formId: 'fffcdecb-7388-4120-83b1-5c8af236472c',
          target: '#hubspotForm',
          locale: lang ?? 'en',
          translations: {
            pt: {
              fieldLabels: {
                firstname: 'Nome',
                lastname: 'Sobrenome',
                email: 'Email',
                company: 'Empresa',
                website: 'Site URL',
                comentarios: 'Mensagem',
              },
            },
          },
        });
      }
    });
  }, [lang]);

  return (
    <Container id="contact">
      <Text size="5xl" id="contact">
        {i18n.t('contactForm.title')}
      </Text>
      <div id="hubspotForm" />
      <script
        charset="utf-8"
        type="text/javascript"
        src="//js.hsforms.net/forms/v2.js"
      />
    </Container>
  );
};

export default HubspotContactForm;
