import styled from 'styled-components';

export const TextContentWrapper = styled.div`
  border-bottom: 2px solid
    ${({ theme, isOpen }) =>
      isOpen ? theme.colors.highlight : theme.colors.divider};
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '68px')};
  max-width: 1070px;
  overflow: hidden;
  padding: 0 1rem;
  position: relative;
  transition: 0.5s all ease;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.link.primary};
    font: ${({ theme }) => theme.font.family.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  div {
    padding: 2rem 3rem;
  }
`;
