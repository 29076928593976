import React from 'react';

import i18n from '@/translate/i18n';
import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { Text } from '@/components/atoms/Typography/styles';

import { CardsWrapper, ContentWrapper } from './styles';
import data from './data';
import BenefitCard from '@/components/molecules/BenefitCard';

function FlashAdvantageSection() {
  return (
    <DefaultContainer bgColor="light">
      <ContentWrapper>
        <Text size="5xl">{i18n.t('flashAdvantageSection.title')}</Text>
        <Text size="xl">{i18n.t('flashAdvantageSection.subTitle')}</Text>
        <Text size="base">{i18n.t('flashAdvantageSection.description')}</Text>
        <CardsWrapper>
          {data.map(({ title, icon }) => (
            <BenefitCard title={title} icon={icon} key={title} />
          ))}
        </CardsWrapper>
      </ContentWrapper>
    </DefaultContainer>
  );
}

export default FlashAdvantageSection;
