import React from 'react';

import i18n from '@/translate/i18n';
import { ExternalLink } from '../Typography/styles';
import { ReactComponent as Flag } from '@/assets/icons/flag-br-circle.svg';
import { ReactComponent as Logo } from '@/assets/eightroom-logo.svg';
import { Wrapper } from './styles';

function PoweredBy() {
  return (
    <Wrapper>
      <span>{i18n.t('header.poweredBy')}</span>
      <ExternalLink href="https://eightroom.com" target="_blank">
        <Logo />
      </ExternalLink>
      <Flag />
    </Wrapper>
  );
}

export default PoweredBy;
