import React from 'react';

import Article from '@/components/molecules/Article';
import Footer from '@/components/organisms/Footer';

import ReturnButton from '@/components/atoms/Button/ReturnButton';
import Header from '@/components/organisms/Header';
import i18n from '@/translate/i18n';
import {
  Container,
  ContentWrapper,
} from '@/components/molecules/Article/styles';

function CookiePolicy() {
  return (
    <>
      <Header />
      <Container>
        <ContentWrapper>
          <ReturnButton />
          <Article
            textContent={i18n.t('cookiePolicy.content', {
              returnObjects: true,
            })}
          />
        </ContentWrapper>
      </Container>
      <Footer />
    </>
  );
}

export default CookiePolicy;
