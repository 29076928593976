import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LanguageSelector from '@/components/molecules/LanguageSelector';
import MerchantMenuBtn from '@/components/atoms/Button/MerchantMenuBtn';
import MerchantNavLinks from '@/components/molecules/MerchantNavLinks';
import MobileModal from '@/components/organisms/MobileModal';
import PoweredBy from '@/components/atoms/PoweredBy';
import useOnClickOutside from '@/components/hooks/useClickOutside';

import {
  Container,
  ContentWrapper,
  LogoWrapper,
  Logo,
  NavWrapper,
} from './styles';

function Header({ bg }) {
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(false);
  useOnClickOutside(ref, setShowMenu);

  useEffect(() => {
    const timer = setTimeout(() => {
      const currentLocation = window.location.href;
      const hasHashParam = currentLocation.includes('#');

      if (hasHashParam) {
        const paramId = `${currentLocation.substring(
          currentLocation.indexOf('#') + 1,
        )}`;
        const element = document.getElementById(paramId);
        if (element) {
          element.scrollIntoView();
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showMenu]);

  return (
    <Container bg={bg} ref={ref}>
      <ContentWrapper>
        <LogoWrapper>
          <Link to="/">
            <Logo />
          </Link>
          <PoweredBy />
        </LogoWrapper>

        <div style={{ display: 'flex' }}>
          <NavWrapper>
            <MerchantNavLinks />
          </NavWrapper>

          <LanguageSelector />
        </div>
      </ContentWrapper>

      <MerchantMenuBtn onClick={() => setShowMenu((state) => !state)} />
      <MobileModal
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        isBusinessMenu
      />
    </Container>
  );
}

Header.propTypes = {
  bg: PropTypes.string,
};
export default Header;
