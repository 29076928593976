import styled from 'styled-components';
import { Swiper } from 'swiper/react';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: red;
  height: 100px;
  padding: 5rem 0;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1070px;
  padding: 5rem 2rem 0 2rem;
  width: 100%;

  img {
    max-width: 390px;
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: flex-start;
    flex-direction: row;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    img {
      max-width: 450px;
    }
  }
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    max-width: 40%;
    text-align: left;

    p {
      max-width: 470px;
    }
  }
`;

export const SwiperWrapper = styled(Swiper)`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 1070px;
  padding: 45px 0;
  width: 100%;

  .swiper-pagination-bullet {
    background: ${({ theme }) => theme.colors.bg.primary};
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.colors.primary};
  }

  .slideNextClass {
    background-color: red;
    width: 50px;
    height: 50px;
  }

  .swiper-btn-prev,
  .swiper-btn-next {
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.link.primary};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.info.dark};
    cursor: pointer;
    font-size: 25px;
    height: 36px;
    left: 20px;
    opacity: 1;
    padding: 5px;
    position: absolute;
    top: 0;
    width: 36px;
    z-index: 11;
  }

  .swiper-btn-next {
    left: 60px;
  }

  .swiper-btn-prev.swiper-button-disabled,
  .swiper-btn-next.swiper-button-disabled {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.link.primary};
  }

  .swiper-button-lock {
    display: none;
  }
`;
